<template>
    <div>
        <el-tabs>
            
            <el-tab-pane label="成绩单">
                <el-button type="primary" @click="exportPDF('showTranscript')" size="medium" style="margin:5px;">导出PDF</el-button>

                <div id="showTranscript">
                  <div class="transcriptContainer" style="margin-bottom: 10px">
                    <!-- 背景图 -->
                    <img src="../../assets/transcript_felc_new.jpg" class="model_p" />

                    <div class="yearClass">2 0 2 3 - 2 0 2 4</div>
                    <!-- 姓名 -->
                    <div class="name">
                      {{
                        item.student_info.student_lastName +
                        item.student_info.student_givenName +
                        " " +
                        upfirstName(item.student_info.student_givenName_pinyin) +
                        " " +
                        upfirstName(item.student_info.student_lastName_pinyin) 
                        
                        
                      }}
                    </div>
                    <!-- 信息 -->
                    <div class="info">
                      <div>
                        <span class="tag">邮箱: </span>
                        <span class="content">{{ item.student_info.student_email }}</span> |
                        <span class="tag">性别: </span>
                        <span class="content">{{
                          item.student_info.student_gender == "男" ? "男" : "女"
                        }}</span>
                        <!-- |
                        <span class="tag">预计毕业年份: </span>
                        <span class="content">{{
                          item.student_info.student_graduation
                        }}</span> -->
                      </div>
                      <div style="margin-top: 6px">
                        <span class="tag">学校: </span>
                        <span class="content">{{ item.account_info.account_name }}</span>
                      </div>
                    </div>
                    <!-- 团队成绩 -->
                    <div v-if="haveTeam == true" class="scoreTitle1">团队项目成绩 </div>

                    <div class="score">
                      <div v-for="(score , index) in item.team_score_info" :key="score._id.$id" class="teamItem">
                        <div v-if="score.score_type == 'team'" style="">
                          <div class="title">
                            {{ score.application_event }}
                          </div>
                          <div style="margin-top: 5px">
                            分数 : <span style="margin-left: 5px;">{{ score.total_score }}</span
                            >/{{ score.full_score }}<br>
                            <!-- <span style="margin-top : 15px"></span>Percentile: Top<span>{{ score.percentile }}</span> -->
                          </div>

                          <!-- <div style="margin-top: 5px">
                            # of Competitors: {{ score.competitor_count }}
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <!-- 个人成绩 -->
                    <div class="scoreTitle2">个人项目成绩 </div>

                    <div class="score1">

                      <div v-for="(score , index) in item.person_score_info" :key="score._id.$id" class="oneClass">
                        <div
                          v-if="score.score_type == 'personal'"
                          style="margin-bottom: 10px"
                        >
                          <div class="title">
                            {{ score.application_event }}
                          </div>
                          <div style="margin-top: 5px;font-size: 12px;">
                            分数 :  <span style="margin-left: 5px;">{{ score.total_score }}</span
                            >/{{ score.full_score }}
                            <!-- <span style="margin-left: 10px"></span>百分位:前
                            <span>{{ score.percentile }}</span> -->
                          </div>
                          <!-- <div style="margin-top: 5px">
                            # of Competitors：{{ score.competitor_count }}
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <div class="time1">{{'2024年8月9日'}}</div>

                  </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { getStudentAwards,getStudentTranscripts,findMyTranscript} from '../../api/index.js'
import {getUserId,getProjectCode} from '../../utils/store'
import {formatDate} from '../../utils/date'

export default {
  name: 'awardfbla',
  data () {
    return {
        project_code:getProjectCode(),
        total_awards: [],
        event_awards: [],
        item: {},
        completes: [],
        transcripts:[],
        type:'all',
        id:'',
        award_create_time:'2022年12月10日',
        award_type_label_arr:{
            'personal total':'Individual Total Score Rank',
            'event rank':'Individual Event Rank',
            'event team rank':'Team Event Rank'
        },
        direction:1,
        haveTeam:false


    }
  },
  mounted () {

    this.type = this.$route.query.type
    this.id = this.$route.query.id

    this.fetchData()
  },
  methods: {
    fetchData () {

        console.log(1111,getProjectCode())
        
        getStudentAwards(getProjectCode(),getUserId()).then(res => {
            if (res.data.code === 0) {
            this.total_awards = res.data.data.filter(
                (item) => item.award_type === 'personal total'
            )
            this.event_awards = res.data.data.filter(
                (item) => item.award_type === 'event rank' || item.award_type === 'event team rank'
            )
            }
        })
        //   getCompletes().then(res => {
        //     if (res.data.code === 0) {
        //       this.completes = res.data.data
        //     }
        //   })

        findMyTranscript(getUserId(), getProjectCode()).then((res) => {
          // loading.close();
          this.item = res.data.data;

          this.item.person_score_info = []
          this.item.team_score_info = []

          for (var i = 0; i < this.item.score_info.length; i++) {

            var info = this.item.score_info[i]

            console.log(1111,info.score_type)

            if (info.score_type == 'team'){
              this.haveTeam = true;
              this.item.team_score_info.push(info)
            }else{
              this.item.person_score_info.push(info)
            }

          }

        });
        // getStudentTranscripts(getProjectCode(),getUserId()).then(res => {
        //     console.log(res)
        //     if (res.data.code === 0) {
        //         this.transcripts = res.data.data


        //         for (var i = 0; i < this.transcripts.length; i++) {

        //           var info = this.transcripts[i]
        //           if (info.score_type == 'team'){
        //             this.haveTeam = true;
        //             this.item.team_score_info.push(info)
        //           }else{
        //             this.item.person_score_info.push(info)
        //           }

        //         }

        //     };

        // })
    
      
    },
    formatCreateTime(create_time) {
      var time = JSON.parse(create_time) * 1000
      var temp_date = new Date(time)
      return formatDate(temp_date, 'yyyy/MM/dd')
    },
    rank (number) {
      var tail = number.toString().charAt(number.toString().length-1,1)
      if (tail === '1' && number !== 11) {
        return "st";
      } else if (tail === '2' && number !== 12) {
        return "nd";
      } else if (tail === '3' && number !== 13) {
        return "rd";
      } else {
        return "th";
      }
    },
    upfirstName (name) {
        if (name) {
            const transferName = name.toLowerCase()
      return transferName.charAt(0).toUpperCase() + transferName.slice(1)     
        }else{
            return name
        }
      
    },
    exportPDF (id) {


        var style = document.createElement('style');

        if (id == 'showTotal'){
            // style.innerHTML = "@page{size:landscape 1528px 1080px;}";
            style.innerHTML = "@page{size:A4 landscape;}";
        }else{
            // style.innerHTML = "@page{size:portrait 2480px 3508px;margin: auto 0mm}";
            style.innerHTML = "@page{size:A4 portrait;margin: auto 0mm}";
            document.getElementsByTagName("body")[0].style.zoom = 1
        }

        window.document.head.appendChild(style)

        const printData = document.getElementById(id).innerHTML


          this.PageSetup_Null();//把页眉页脚设置为空


        window.document.body.innerHTML = printData // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
        window.print() // 开始打印
    },
    PageSetup_Null(){
        var HKEY_Root,HKEY_Path,HKEY_Key;

        HKEY_Root="HKEY_CURRENT_USER";

        HKEY_Path="\\Software\\Microsoft\\Internet Explorer\\PageSetup\\";

        try{
            var Wsh=new ActiveXObject("WScript.Shell");

            HKEY_Key="header";

            Wsh.RegWrite(HKEY_Root+HKEY_Path+HKEY_Key,"");

            HKEY_Key="footer";

            Wsh.RegWrite(HKEY_Root+HKEY_Path+HKEY_Key,"");

        }catch(e){}

        }

  }
}
</script>

<style lang="scss">
@font-face {
    font-family: 'aleo-bold';
    src: url('../../assets/font/aleo-bold.ttf');
}
@font-face {
    font-family: 'Aleo-Bold-2';
    src: url('../../assets/font/Aleo-Bold-2.otf');
}
@font-face {
    font-family: 'Aleo-Italic-4';
    src: url('../../assets/font/Aleo-Italic-4.otf');
}
@font-face {
    font-family: 'Aleo-Light-5';
    src: url('../../assets/font/Aleo-Light-5.otf');
}
@font-face {
    font-family: 'Aleo-Regular-7';
    src: url('../../assets/font/Aleo-Regular-7.otf');
}
.certContainer {
  position: relative;
  color: rgb(42, 70, 154);
  .model {
    width: 297mm;
    height: 200.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 430px;
    left: 116px;
    font-weight: 500;
  }
  .ziZm{
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm{
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 525px;
    left: 250px;
    font-weight: 500;
  }
  .projectName{
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh{
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }
   
  .tefaLab{
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab{
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 300px;
    left: 116px;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    .name {
      margin-right: 40px;
    }
  }
  .email{
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 260px;
    left: 116px;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg{
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    // background-color:  white;
  }
}
.certContainer1{
    position: relative;
    color: white;
    .model{
        width: 365.28mm;
        height: 205.47mm;
        z-index: -10;   
    }
    .event{
        position: absolute;
        font-size: 70px;
        top: 100px;
        left: 86px;
        font-weight: 300;
        width: 600px;
    }
    .award{
        position: absolute;
        font-size: 90px;
        top: 300px;
        left: 86px;
        font-weight: 600;
    }
    .student{
        position: absolute;
        width: 600px;
        top: 460px;
        left: 86px;
        font-size: 40px;
        font-style: italic;
        font-weight: 300;
        .name{
            margin-right: 40px;
            word-wrap: none;
        }
    }
    .school{
        position: absolute;
        top: 570px;
        left: 86px;
        font-size: 30px;
        font-weight: 300;
    }
}
.certContainer4{
  position: relative;
  color: rgb(42, 70, 154);
  .model {
    width: 297mm;
    height: 208.7mm;
  }
  .event {
    position: absolute;
    font-size: 28px;
    top: 430px;
    left: 116px;
    font-weight: 500;
  }
  .ziZm{
    position: absolute;
    font-size: 18px;
    top: 220px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .rhZm{
    position: absolute;
    font-size: 18px;
    top: 390px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .award {
    position: absolute;
    font-size: 28px;
    top: 525px;
    left: 250px;
    font-weight: 500;
  }
  .projectName{
    position: absolute;
    font-size: 28px;
    top: 480px;
    left: 250px;
    font-weight: 500;
  }
  .awardPh{
    position: absolute;
    font-size: 18px;
    top: 565px;
    left: 250px;
    font-weight: 400;
  }

  .tefaLab{
    position: absolute;
    font-size: 18px;
    top: 615px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .timeLab{
    position: absolute;
    font-size: 18px;
    top: 655px;
    left: 116px;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 300px;
    left: 116px;
    font-size: 35px;
    // font-style: italic;
    font-weight: 600;
    .name {
      margin-right: 40px;
    }
  }
  .email{
    position: absolute;
    top: 350px;
    left: 116px;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    top: 260px;
    left: 116px;
    font-size: 23px;
    font-weight: 350;
  }
  .saiquImg{
    position: absolute;
    top: 480px;
    left: 116px;
    width: 120px;
    height: 110px;
    // background-color:  white;
  }
}
.certContainerFelc{
  position: relative;
  color: #333;
  font-family: 'SourceHanSansCN-Bold';

  .model {
    width: 210mm;
    height: 295mm;
    z-index: 0;
  }
  .event {
    position: absolute;
    font-size: 25px;
    top: 550px;
    // left: 116px;
    font-weight: 600;
    text-align: center;
    width: 210mm;
  }
 
  .award {
    position: absolute;
    font-size: 38px;
    top: 645px;
    left: 105mm;
    font-weight: 500;
    color: #233C85;
  }
  .projectName{
    position: absolute;
    font-size: 20px;
    top: 505px;
    // left: 250px;
    text-align: center;
    width: 210mm;
    font-weight: 600;
  }
  .awardPh{
    font-family: 'SourceHanSansCN-Normal';
    position: absolute;
    font-size: 18px;
    top: 700px;
    left: 105mm;
    color: gray;
    font-weight: 400;
  }

  .tefaLab{
    position: absolute;
    font-size: 20px;
    top: 460px;
    font-weight: 600;
    text-align: center;
    width: 210mm;
    color: black;
  }
  .timeLab{
    font-family: 'SourceHanSansCN-Normal';
    position: absolute;
    font-size: 18px;
    top: 808px;
    left: 105mm;
    font-weight: 350;
    color: black;
  }
  .student {
    position: absolute;
    top: 330px;
    // left: 116px;
    text-align: center;
    font-size: 35px;
    width: 210mm;
    // background-color: #003262;
    // font-style: italic;
    font-weight: 600;
    .name {
      // margin-right: 40px;
    }
  }
  .email{
    font-family: 'SourceHanSansCN-Normal';
    position: absolute;
    top: 380px;
    // left: 116px;
    width: 210mm;
    text-align: center;
    font-size: 20px;
    // font-style: italic;
    font-weight: 350;
  }
  .school {
    position: absolute;
    font-weight: 600;
    width: 210mm;
    text-align: center;
    top: 295px;
    // left: 116px;
    font-size: 23px;
    font-weight: 450;
  }
  .saiquImg{
    position: absolute;
    top: 620px;
    left: 250px;
    width: 140px;
    height: 130px;
    // background-color:  white;
  }
}

.transcriptContainer {
  position: relative;
  margin-bottom: -4px;
  .model_p {
    width: 210mm;
    height: 295mm;
    z-index: 0;
  }
  .name {
    position: absolute;
    top: 356px;
    left: 85px;
    font-size: 25px;
    font-weight: 500;
  }
  .info {
    position: absolute;
    top: 404px;
    left: 85px;
    font-size: 15px;
    .tag {
      font-family: "Aleo-Bold-2";
    }
    .content {
      font-family: "Aleo-Regular-7";
    }
  }
  .score {
    position: absolute;
    top: 820px;
    width: 620px;
    // display: flex;
    display: flex;
    flex-wrap: wrap; /* 允许换行 */
    left: 85px;
    font-size: 15px;
    font-family: "Aleo-Regular-7";
    span {
      font-family: "Aleo-Bold-2";
      font-size: 17px;
    }
    .title {
      font-family: "aleo-bold";
    }
    .teamItem{
      width: 280px;
      // background-color: yellow;
      margin-right: 20px;
    }
    
  }
  .scoreTitle2{
      // font-size: 20px;
      // margin-bottom: 10px;
      // font-weight: 500;
      // color: #0f429e;
      position: absolute;
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: 550;
      color: #0f429e;
      top: 470px;
      width: 220px;
      left: 85px;
    }
  .scoreTitle1{
    position: absolute;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 550;
    color: #0f429e;
    top: 780px;
    width: 220px;
    left: 85px;

  }
  .score1 {
    position: absolute;
    top: 500px;
    width: 620px;
    // background-color: rebeccapurple;
    left: 85px;
    display: flex;
    flex-wrap: wrap; /* 允许换行 */
    font-size: 15px;
    font-family: "Aleo-Regular-7";
    span {
      font-family: "Aleo-Bold-2";
      font-size: 17px;
    }
    .title {
      font-family: "aleo-bold";
    }
    .scoreTitle{
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: 500;
      color: #0f429e;
    }

    .oneClass{
      margin-top: 15px;
      width: 280px;
      // background-color: yellow;
      margin-right: 20px;
    }  
    

  }
  .yearClass{
    position:absolute;
    font-size: 20px;
    color: white;
    left: 135px;
    top: 237px;
    // background-color: rebeccapurple;
  }
  
  .time1{
        position: absolute;
        top: 950px;
        width:320px;
        left:85px;
        font-size: 18px;
        font-weight: 600;
        color: #2A358A;
        // font-family: 'Aleo-Regular-7';
        // span{
        //     font-family: 'Aleo-Bold-2';
        //     font-size: 14px;
        // }
        // .title{
        //     font-family: 'aleo-bold';
        // }
    }
}

</style>
